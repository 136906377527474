import { RealElectionType, TelepulesType } from "../util/magnetHelper";

export type ShowByType = "ByElection" | "ByOsszefogasParty" | "ByLocationType";

export type OrszagosByType =
	| "Összefogás"
	| "Fidesz"
	| "Összefogás-Fidesz"
	| "Összefogás-Fidesz (választási átlag)"
	| "Összefogás-Fidesz (percentilis)"
	| "Részvétel"
	| "Választópolgár";

export const OrszagosByTypeDataSource = [
	"Összefogás",
	"Fidesz",
	"Összefogás-Fidesz",
	"Összefogás-Fidesz (választási átlag)",
	"Összefogás-Fidesz (percentilis)",
	"Részvétel",
	"Választópolgár",
];

export interface OsszefogasShowByVM {
	type: ShowByType;
	election?: RealElectionType;
	party?: string;
	locationType?: TelepulesType;
}
