import React from "react";
import Oevk from "../cc/oevk";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { isBrowser } from "../util/helper";

const OevksPage = () => {
	return (
		<Layout>
			<SEO title="OEVK" />
			<div className="container px-4">{isBrowser() ? <Oevk /> : ""}</div>
		</Layout>
	);
};

export default OevksPage;
