/* eslint-disable no-console */
/* eslint-disable no-null/no-null */
import { html, svg as litSvg } from "lit-html";
import { useEffect, useMemo, useState, useWindowSize } from "../../util/CustomHauntedHooks";
import * as d3 from "d3";
import "./country.scss";
import OEVKS from "../../../data/oevk.geo.simplified.d3.json";
import Master from "../../../data/data-geo2.json";
import { Dictionary, mapMasterToOEVK, placeRatio } from "../../util/GeoModels";
import { isEmpty, transcode } from "../../util/helper";

export interface CountryProperties {
	oevkKey: string | undefined;
}

const COLORS = {
	selected: "#1a237e",
	hover: "#2979ff",
};

type ColorSchema = "Default" | "Billego";

const OEVK_TO_MASTER_MAPPING: Dictionary = Master.reduce((aggr, curr) => {
	aggr[mapMasterToOEVK(curr.OEVK)] = curr.OEVK;
	return aggr;
}, {} as Dictionary);

const getMasterByOevk = (oevkName: string) => {
	return Master.find((m) => m.OEVK === OEVK_TO_MASTER_MAPPING[oevkName]);
};

export const useCountry = (props: CountryProperties) => {
	// HELPER
	const updateSVG = () => {
		window.setTimeout(() => {
			const width = DEFAULTS.viewBoxWidth;
			const height = (500 * DEFAULTS.viewBoxWidth) / 750;

			const color = d3
				.scaleLinear()
				.domain([1, 20])
				.clamp(true)
				.range(["#fff", "#fcf883"] as any);

			const projection = d3
				.geoMercator()
				.scale(2500 + 8.3 * (DEFAULTS.viewBoxWidth - 328))
				// Center the Map to Hungary
				.center([19.5, 47.16])
				.translate([width / 2, height / 2]);

			const path = d3.geoPath(projection);

			const svg = d3.select(".hungary").attr("width", width).attr("height", height);

			const mapLayer = svg.select(".map-layer");
			// const effectLayer = svg.select(".effect-layer");

			function oevkRectClicked(this: d3.BaseType) {
				mapLayer
					.selectAll("path")
					.style("fill", (d) => getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>));

				setSelectedOEVKFeature(undefined);
			}

			function oevkClicked(
				this: d3.BaseType | SVGPathElement,
				_e: Event,
				f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
			) {
				setSelectedOEVKFeature(selectedOEVKFeature !== f ? f : undefined);

				mapLayer.selectAll("path").style("fill", function (d) {
					return selectedOEVKFeature && d === selectedOEVKFeature
						? COLORS.selected
						: getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>);
				});
			}

			function oevkMouseOver(
				this: d3.BaseType | SVGPathElement,
				_e: Event,
				f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
			) {
				d3.select(this).style("fill", COLORS.hover);
				setSelectedOEVKHover(selectedOEVKFeature !== f ? f?.properties?.name : "");
			}

			function oevkMouseOut(this: d3.BaseType | SVGPathElement, _e: Event) {
				mapLayer.selectAll("path").style("fill", (d) => {
					return selectedOEVKFeature && d === selectedOEVKFeature
						? COLORS.selected
						: getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>);
				});
				setSelectedOEVKHover("");
			}

			const getColor = (f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>): any => {
				if (colorSchema === "Default") {
					const num = f && f.properties ? f.properties.name.length : 0;
					return color(num);
				}

				if (colorSchema === "Billego") {
					let c = "#fff";
					const master = getMasterByOevk(f?.properties?.name);
					if (master !== undefined) {
						if (master["21 kk"] === "Kormánypárti") {
							c = "#ffe3cc";
						}
						if (master["21 kk"] === "Ellenzéki") {
							c = "#abc9ff";
						}
						if (master["21 kk"] === "Billegő") {
							c = "red";
						}
					}

					return c;
				}
			};

			svg.select("rect").attr("width", width).attr("height", height).on("click", oevkRectClicked);

			const mapData = OEVKS as GeoJSON.FeatureCollection;

			// normalize coropleth scale
			// color.domain([0, d3.max(mapData.features, getColor)]);

			mapLayer
				.selectAll("path")
				.data(mapData.features)
				.join("path")
				.attr("d", path)
				.attr("vector-effect", "non-scaling-stroke")
				.style("fill", (f) => (f === selectedOEVKFeature ? COLORS.selected : getColor(f)))
				.on("mouseover", oevkMouseOver)
				.on("mouseout", oevkMouseOut)
				.on("click", oevkClicked);
		}, 0);
	};

	// COMPONENT

	const windowSize = useWindowSize();
	const DEFAULTS = {
		viewBoxWidth: Math.min(windowSize[0], 500) - 20,
		viewBoxHeight: windowSize[0] > 768 ? 200 : 200,
	};

	const [selectedOEVKFeature, setSelectedOEVKFeature] = useState<
		GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | undefined
	>(undefined);
	const [selectedOEVKHover, setSelectedOEVKHover] = useState<string>("");
	const selectedOEVK = useMemo<string>(
		() => selectedOEVKFeature?.properties?.name?.replace(" ", "@"),
		[selectedOEVKFeature]
	);
	const [colorSchema, setColorSchema] = useState<ColorSchema>("Default");

	useEffect(() => {
		if (props.oevkKey) {
			const mapData = OEVKS as GeoJSON.FeatureCollection;
			const feature = mapData.features.find(
				(f) => f?.properties?.name?.toUpperCase() === props.oevkKey?.replace("@", " ")
			);
			if (feature) {
				setSelectedOEVKFeature(feature);
			}
		}
	}, [props.oevkKey]);

	useEffect(() => {
		updateSVG();
	}, [selectedOEVKFeature, colorSchema, DEFAULTS.viewBoxWidth]);

	const htmlTemplate = () => {
		return html`<div>
			<svg class="hungary">
				<rect class="background"></rect>
				<g>
					<g class="effect-layer"></g>
					<g class="map-layer"></g>
				</g>
			</svg>
			<div>${selectedOEVKHover}&#8195</div>
		</div>`;
	};

	return { template: htmlTemplate, selectedOEVK };
};
