/* eslint-disable no-console */
import "./oevk.scss";
import "../ui/dc-components";
import { html } from "lit-html";
import { HauntedFunc, useEffect, useMemo, useQueryState, useState } from "../util/CustomHauntedHooks";
import { component } from "haunted";
import * as DC from "../ui/dc-components-typing";
import * as _ from "lodash";
import React from "react";
import useCustomElement from "../util/useCustomElement";
import { isBrowser, isEmpty } from "../util/helper";
import { REAL_MAGNETS, roundPercentage } from "./chief_helper";
import { sluggify } from "../util/sluggifier";
import {
	getOevkKey,
	NumDict,
	OevkDetail,
	RealElectionType,
	REAL_ELECTIONS,
	sumOsszefogasNumDict,
	TelepulesType,
} from "../util/magnetHelper";
import { useBarChart } from "./svg/barChart";
import { OrszagosByType, OrszagosByTypeDataSource, OsszefogasShowByVM } from "./oevk_helper";
import { useCountry } from "./svg/country";
import { formatNumber } from "../util/numberHelper";

const observedAttributes: (keyof Properties)[] = [];
const useShadowDOM = false;
const name = "th-oevk";

interface Properties {}

const Component: HauntedFunc<Properties> = () => {
	// HELPER

	const getOevkDetail = (type: RealElectionType) => {
		return Object.values(REAL_MAGNETS[type].oevks).filter(
			(o) => sluggify(getOevkKey(o.county, o.oevk, "-")) === oevkName
		)[0];
	};

	const getEllenzekParties = () => {
		if (oevkDetails === undefined) {
			return [];
		}

		return _.uniq(
			Object.keys(oevkDetails).reduce((aggr, key) => {
				const oevkDetail = oevkDetails[key] as OevkDetail;
				return aggr.concat(
					Object.keys(oevkDetail.ind).filter((p) => p !== "FIDESZ" && p !== "EGYEB" && p !== "MKKP")
				);
			}, [] as string[])
		);
	};

	const getEllenzekPartiesDataSource = () => {
		return [{ label: "", value: undefined as unknown as string }].concat(
			getEllenzekParties().map((p) => ({ label: p, value: p }))
		);
	};

	const getElections = () => {
		return REAL_ELECTIONS;
	};

	const getElectionsDataSource = () => {
		return [{ label: "", value: undefined as unknown as string }].concat(
			getElections().map((p) => ({ label: p.indexOf("EP") > -1 ? p : p.substring(0, 5), value: p }))
		);
	};

	const getLocationTypes = (): TelepulesType[] => {
		return ["kf", "nf", "v", "ME", "FO"];
	};

	const getLocationTypesDataSource = () => {
		return [
			{ label: "", value: undefined as unknown as string },
			{ label: "Kisebb falu, város (<2 500 fő)", value: "kf" },
			{ label: "Közepes falu, város (2 500< <10 000 fő)", value: "nf" },
			{ label: "Nagyobb falu, város (10 000< fő)", value: "v" },
			{ label: "Megyei jogú város, megyeszékhely", value: "ME" },
			{ label: "Fővárosi kerület", value: "FO" },
		];
	};

	// Orszagos

	const getElectionLocationTypeKey = (election: RealElectionType, locationType: TelepulesType) =>
		`${election}@${locationType}`;

	const getOrszagosDiffMatrix = () => {
		const matrix = {};
		getElections().forEach((e) => {
			getLocationTypes().forEach((l) => {
				const key = getElectionLocationTypeKey(e, l);
				const election = REAL_MAGNETS[e];
				Object.keys(election.oevks).forEach((oevkKey) => {
					const oevk = election.oevks[oevkKey];
					const osszefogasSum = _.sumBy(
						Object.keys(oevk.telepulesek[l]?.ind ?? ({} as NumDict)),
						(partyKey) =>
							partyKey !== "FIDESZ" && partyKey !== "EGYEB" && partyKey !== "MKKP"
								? oevk.telepulesek[l]?.ind[partyKey] ?? 0
								: 0
					);
					const fidesz = oevk.telepulesek[l]?.ind["FIDESZ"] ?? 0;
					const oevkDiff = osszefogasSum - fidesz;
					matrix[key] = (matrix[key] ?? []).concat([oevkDiff]);
				});
			});
		});

		return matrix;
	};

	const sumOsszefogasByElectionAndLocationType = (election: RealElectionType, locationType: TelepulesType) => {
		return _.sumBy(getEllenzekParties(), (p) => getEllenzekValue(election, p, locationType) ?? 0);
	};

	const sumFideszByElectionAndLocationType = (election: RealElectionType, locationType: TelepulesType) => {
		return getEllenzekValue(election, "FIDESZ", locationType) ?? 0;
	};

	const formatOrszagosBy = (orszagosBy: OrszagosByType, election: RealElectionType, locationType: TelepulesType) => {
		const val = getOrszagosValue(orszagosBy, election, locationType);
		if (val === undefined) {
			return "";
		} else {
			return `${formatNumber(val)}${orszagosBy === "Részvétel" ? "%" : ""}`;
		}
	};

	const getOrszagosValue = (orszagosBy: OrszagosByType, election: RealElectionType, locationType: TelepulesType) => {
		const osszefogas = sumOsszefogasByElectionAndLocationType(election, locationType);
		const fidesz = sumFideszByElectionAndLocationType(election, locationType);
		const eligibleVoters = oevkDetails ? oevkDetails[election]?.telepulesek[locationType]?.eligibleVoters ?? 0 : 0;
		const allVoters = oevkDetails
			? _.sum(Object.values(oevkDetails[election].telepulesek[locationType]?.ind ?? []))
			: 0;
		if (fidesz === 0 || osszefogas === 0) {
			return undefined;
		}

		if (orszagosBy === "Összefogás") {
			return osszefogas;
		}

		if (orszagosBy === "Fidesz") {
			return fidesz;
		}

		if (orszagosBy === "Összefogás-Fidesz") {
			return osszefogas - fidesz;
		}

		if (orszagosBy === "Összefogás-Fidesz (választási átlag)") {
			const mean = _.mean(orszagosDiffMatrix[getElectionLocationTypeKey(election, locationType)]);
			return Math.round(osszefogas - fidesz - mean);
		}

		if (orszagosBy === "Összefogás-Fidesz (percentilis)") {
			const diff = osszefogas - fidesz;
			const N = orszagosDiffMatrix[getElectionLocationTypeKey(election, locationType)].length;
			const n = _.sumBy(orszagosDiffMatrix[getElectionLocationTypeKey(election, locationType)], (v: number) =>
				v < diff ? 1 : 0
			);
			return Math.round((n / N) * 100);
		}

		if (orszagosBy === "Választópolgár") {
			return eligibleVoters;
		}

		if (orszagosBy === "Részvétel") {
			return roundPercentage(allVoters / eligibleVoters);
		}

		return undefined;
	};

	// Ellenzek

	const hasEllenzekValueSumByParty = (election: RealElectionType, locationType: TelepulesType) => {
		return getEllenzekParties().some((p) => getEllenzekValue(election, p, locationType) !== undefined);
	};

	const hasEllenzekValueSumByLocationType = (election: RealElectionType, party: string) => {
		return getLocationTypes().some((l) => getEllenzekValue(election, party, l as TelepulesType) !== undefined);
	};

	const hasEllenzekValueSumByElection = (party: string, locationType: TelepulesType) => {
		return getElections().some((e) => getEllenzekValue(e as RealElectionType, party, locationType) !== undefined);
	};

	const getEllenzekValue = (election: RealElectionType, party: string, locationType: TelepulesType) => {
		if (oevkDetails === undefined) {
			return 0;
		}
		return oevkDetails[election]?.telepulesek[locationType]?.ind[party];
	};

	// COMPONENT

	const [oevkName, setOevkName] = useQueryState<string>("name", "");
	const currentOevkKey = useMemo(() => {
		if (!isEmpty(oevkName)) {
			const oevkDetail = Object.values(REAL_MAGNETS["2018PAPR"].oevks).filter(
				(o) => sluggify(getOevkKey(o.county, o.oevk, "-")) === oevkName
			)[0];
			return getOevkKey(oevkDetail.county, oevkDetail.oevk, "@");
		} else {
			return undefined;
		}
	}, [oevkName]);
	const [osszefogasShowBy, setOsszefogasShowBy] = useState<OsszefogasShowByVM>({
		type: "ByElection",
		election: "2018PAPR",
	});
	const [orszagosBy, setOrszagosBy] = useState<OrszagosByType>("Összefogás");
	const oevkDetails = useMemo(() => {
		if (!isEmpty(oevkName)) {
			return {
				"2006PAPR": getOevkDetail("2006PAPR"),
				"2010PAPR": getOevkDetail("2010PAPR"),
				"2014PAPR": getOevkDetail("2014PAPR"),
				"2018PAPR": getOevkDetail("2018PAPR"),
				"2019EP": getOevkDetail("2019EP"),
			};
		} else {
			return undefined;
		}
	}, [oevkName]);
	const barChartProps = useMemo(() => {
		if (oevkDetails !== undefined) {
			const data = REAL_ELECTIONS.map((type) => {
				const dict = sumOsszefogasNumDict(oevkDetails[type].ind);
				return dict.OSSZEFOGAS - dict.FIDESZ;
			});
			return { data, labels: REAL_ELECTIONS.map((p) => (p.indexOf("EP") > -1 ? p : p.substring(0, 5))) };
		} else {
			return { data: [], labels: [] };
		}
	}, [oevkDetails]);
	const barChart = useBarChart(barChartProps);
	const orszagosDiffMatrix = useMemo(() => getOrszagosDiffMatrix(), []);
	const countryGeo = useCountry({ oevkKey: currentOevkKey });

	useEffect(() => {
		if (countryGeo.selectedOEVK) {
			const [county, oevk] = countryGeo.selectedOEVK.split("@");
			setOevkName(sluggify(getOevkKey(county, oevk, "-")));
		}
	}, [countryGeo.selectedOEVK]);

	// useEffect(() => {
	// 	if (oevkDetails) {
	// 		console.log(oevkDetails);
	// 	}
	// }, [oevkDetails]);

	// TEMPLATE

	const htmlEgyeni = () => {
		return html`<div>
			<div class="font-bold">Egyéni eredmény</div>
			<div class="flex">
				<div class="md:w-1/2">${oevkDetails ? barChart.template() : ""}</div>
				<div class="hidden md:block md:w-1/2">${countryGeo.template()}</div>
			</div>
		</div>`;
	};

	const htmlOrszagos = () => {
		return html`<div>
			<div class="font-bold">Országos választási eredmények</div>
			<div class="md:w-1/3 mt-4">
				<dc-select
					.dataSource=${OrszagosByTypeDataSource}
					.selectedValues=${orszagosBy}
					@change=${(e: DC.Select.SelectChangeEvent) => {
						setOrszagosBy(e.detail.selectedValue as OrszagosByType);
					}}
				></dc-select>
			</div>
			<div class="mt-4">
				<table class="of-table">
					<thead>
						<th></th>
						${getElectionsDataSource()
							.splice(1)
							.map((e) => html`<th>${e.label}</th>`)}
					</thead>
					<tbody>
						${getLocationTypesDataSource()
							.splice(1)
							.map(
								(l) => html`<tr>
									<td>${l.label}</td>
									${getElections().map(
										(e) =>
											html`<td>
												${formatOrszagosBy(
													orszagosBy,
													e as RealElectionType,
													l.value as TelepulesType
												)}
											</td>`
									)}
								</tr>`
							)}
					</tbody>
				</table>
			</div>
		</div>`;
	};

	const htmlEllenzekByElection = () => {
		return html`<div>
			<table class="of-table">
				<thead>
					<th>${getElectionsDataSource().find((ds) => ds.value === osszefogasShowBy.election)?.label}</th>
					${getEllenzekPartiesDataSource()
						.splice(1)
						.filter((p) =>
							hasEllenzekValueSumByLocationType(osszefogasShowBy.election as RealElectionType, p.value)
						)
						.map((p) => html`<th>${p.label}</th>`)}
				</thead>
				<tbody>
					${getLocationTypesDataSource()
						.splice(1)
						.filter((l) =>
							hasEllenzekValueSumByParty(
								osszefogasShowBy.election as RealElectionType,
								l.value as TelepulesType
							)
						)
						.map(
							(l) => html`<tr>
								<td>${l.label}</td>
								${getEllenzekParties()
									.filter((p) =>
										hasEllenzekValueSumByLocationType(
											osszefogasShowBy.election as RealElectionType,
											p
										)
									)
									.map(
										(p) =>
											html`<td>
												${formatNumber(
													getEllenzekValue(
														osszefogasShowBy.election as RealElectionType,
														p as string,
														l.value as TelepulesType
													)
												)}
											</td>`
									)}
							</tr>`
						)}
				</tbody>
			</table>
		</div>`;
	};

	const htmlEllenzekByParty = () => {
		return html`<div>
			<table class="of-table">
				<thead>
					<th>${getEllenzekPartiesDataSource().find((ds) => ds.value === osszefogasShowBy.party)?.label}</th>
					${getElectionsDataSource()
						.splice(1)
						.filter((e) =>
							hasEllenzekValueSumByLocationType(
								e.value as RealElectionType,
								osszefogasShowBy.party as string
							)
						)
						.map((e) => html`<th>${e.label}</th>`)}
				</thead>
				<tbody>
					${getLocationTypesDataSource()
						.splice(1)
						.filter((l) =>
							hasEllenzekValueSumByElection(osszefogasShowBy.party as string, l.value as TelepulesType)
						)
						.map(
							(l) => html`<tr>
								<td>${l.label}</td>
								${getElections()
									.filter((e) =>
										hasEllenzekValueSumByLocationType(
											e as RealElectionType,
											osszefogasShowBy.party as string
										)
									)
									.map(
										(e) =>
											html`<td>
												${formatNumber(
													getEllenzekValue(
														e as RealElectionType,
														osszefogasShowBy.party as string,
														l.value as TelepulesType
													)
												)}
											</td>`
									)}
							</tr>`
						)}
				</tbody>
			</table>
		</div>`;
	};

	const htmlEllenzekByLocationType = () => {
		return html`<div>
			<table class="of-table">
				<thead>
					<th>
						${getLocationTypesDataSource().find((ds) => ds.value === osszefogasShowBy.locationType)?.label}
					</th>
					${getElectionsDataSource()
						.splice(1)
						.filter((e) =>
							hasEllenzekValueSumByParty(
								e.value as RealElectionType,
								osszefogasShowBy.locationType as TelepulesType
							)
						)
						.map((e) => html`<th>${e.label}</th>`)}
				</thead>
				<tbody>
					${getEllenzekPartiesDataSource()
						.splice(1)
						.filter((p) =>
							hasEllenzekValueSumByElection(p.value, osszefogasShowBy.locationType as TelepulesType)
						)
						.map(
							(p) => html`<tr>
								<td>${p.label}</td>
								${getElections()
									.filter((e) =>
										hasEllenzekValueSumByParty(
											e as RealElectionType,
											osszefogasShowBy.locationType as TelepulesType
										)
									)
									.map(
										(e) =>
											html`<td>
												${formatNumber(
													getEllenzekValue(
														e as RealElectionType,
														p.value as string,
														osszefogasShowBy.locationType as TelepulesType
													)
												)}
											</td>`
									)}
							</tr>`
						)}
				</tbody>
			</table>
		</div>`;
	};

	const htmlEllenzek = () => {
		return html`<div>
			<div class="font-bold">Ellenzéki eredmények</div>
			<div>
				<div class="space-y-4 md:flex md:space-x-4 md:space-y-0 mt-4">
					<dc-select
						.label=${"Választás alapján"}
						.dataSource=${getElectionsDataSource}
						.selectedValues=${osszefogasShowBy.election}
						@change=${(e: DC.Select.SelectChangeEvent) => {
							setOsszefogasShowBy({
								type: "ByElection",
								election: e.detail.selectedValue as RealElectionType,
								party: undefined,
								locationType: undefined,
							});
						}}
					></dc-select>
					<dc-select
						.label=${"Párt alapján"}
						.dataSource=${getEllenzekPartiesDataSource}
						.selectedValues=${osszefogasShowBy.party}
						@change=${(e: DC.Select.SelectChangeEvent) => {
							setOsszefogasShowBy({
								type: "ByOsszefogasParty",
								election: undefined,
								party: e.detail.selectedValue,
								locationType: undefined,
							});
						}}
					></dc-select>
					<dc-select
						.label=${"Településtípus alapján"}
						.dataSource=${getLocationTypesDataSource}
						.selectedValues=${osszefogasShowBy.locationType}
						@change=${(e: DC.Select.SelectChangeEvent) => {
							setOsszefogasShowBy({
								type: "ByLocationType",
								election: undefined,
								party: undefined,
								locationType: e.detail.selectedValue as TelepulesType,
							});
						}}
					></dc-select>
				</div>
				<div class="mt-4">
					${osszefogasShowBy.type === "ByElection" ? htmlEllenzekByElection() : ""}
					${osszefogasShowBy.type === "ByOsszefogasParty" ? htmlEllenzekByParty() : ""}
					${osszefogasShowBy.type === "ByLocationType" ? htmlEllenzekByLocationType() : ""}
				</div>
			</div>
		</div>`;
	};

	const htmlDetails = () => {
		return html`<div class="mt-4 space-y-8">
			${htmlEgyeni()}${oevkDetails !== undefined ? html`${htmlOrszagos()}${htmlEllenzek()}` : ""}
		</div>`;
	};

	return html`<div class="mb-4">
		<div class="hidden md:block">
			<h1 class="pt-12 text-2xl leading-tight font-semibold">
				${currentOevkKey ? currentOevkKey?.replace("@", " ") : "Válasszon OEVK-t"}
			</h1>
		</div>
		<div class="md:hidden">
			<dc-select
				.dataSource=${Object.keys(REAL_MAGNETS["2018PAPR"].oevks).map((key) => ({
					label: key.replace("@", " "),
					value: key,
				}))}
				.selectedValues=${currentOevkKey}
				@change=${(e: DC.Select.SelectChangeEvent) => {
					setOevkName(sluggify(e.detail.selectedValue?.replace("@", " ") ?? ""));
				}}
			></dc-select>
		</div>
		<div>${htmlDetails()}</div>
	</div>`;
};

if (isBrowser() && customElements.get(name) === undefined) {
	customElements.define(
		name,
		component<HTMLElement & Properties>(Component, {
			useShadowDOM,
			observedAttributes,
		})
	);
}

// React Wrapper

const Oevk = (props) => {
	const [ref] = useCustomElement(props);
	return (
		<div>
			<div className="mt-4">
				<th-oevk ref={ref}></th-oevk>
			</div>
		</div>
	);
};

export default Oevk;
