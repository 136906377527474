/* eslint-disable no-console */
/* eslint-disable no-null/no-null */
import { html, svg as litSvg } from "lit-html";
import { useEffect, useRef, useWindowSize } from "../../util/CustomHauntedHooks";
import * as d3 from "d3";
import "./barChart.scss";

export interface BarChartProperties {
	labels: string[];
	data: number[];
}
export const useBarChart = (props: BarChartProperties) => {
	// HELPER

	// COMPONENT

	const windowSize = useWindowSize();

	const DEFAULTS = {
		viewBoxWidth: Math.min(windowSize[0], 500) - 20,
		viewBoxHeight: windowSize[0] > 768 ? 200 : 200,
	};

	useEffect(() => {
		window.setTimeout(() => {
			if (props.data) {
				const margin = { top: 10, right: 10, bottom: 10, left: 40 },
					width = DEFAULTS.viewBoxWidth - margin.left - margin.right,
					height = DEFAULTS.viewBoxHeight - margin.top - margin.bottom;

				const svgRoot = d3.select("#js_svg_root");
				svgRoot.selectAll("*").remove();

				const svg = svgRoot
					.attr("width", width + margin.left + margin.right)
					.attr("height", height + margin.top + margin.bottom)
					.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

				const x = d3
					.scaleBand()
					.rangeRound([0, width])
					.domain(props.data.map((d) => d.toString()))
					.padding(0.5);

				const y0 = Math.max(Math.abs(d3.min(props.data) ?? 0), Math.abs(d3.max(props.data) ?? 0));
				const y = d3.scaleLinear().domain([-y0, y0]).range([height, 0]).nice();

				// Y axis
				const yAxis = d3.axisLeft(y);
				svg.append("g")
					.attr("class", "y axis")
					.call(yAxis)
					.call((g) =>
						g.selectAll(".tick").call((tick) => {
							return tick
								.append("line")
								.attr("class", "grid")
								.attr("x2", width)
								.attr("stroke", "currentColor")
								.attr("stroke-opacity", 0.1);
						})
					);

				// Bars
				svg.selectAll(".bar")
					.data(props.data)
					.enter()
					.append("rect")
					.attr("class", (d) => {
						return d < 0 ? "bar negative" : "bar positive";
					})
					.attr("y", (d) => {
						return y(Math.max(0, d));
					})
					.attr("x", (d) => x(d.toString()) as number)
					.attr("height", (d) => {
						return Math.abs(y(d) - y(0));
					})
					.attr("width", x.bandwidth());

				// X axis
				const xAxis = d3.axisBottom(x).tickSize(0);
				svg.append("g")
					.attr("class", "x axis")
					.attr("transform", "translate(0," + height / 2 + ")")
					.call(xAxis)
					.selectAll("text")
					.data(props.labels)
					.text((d) => d);
			}
		}, 0);
	}, [props.data]);

	const htmlTemplate = () => {
		return html`<div>${litSvg`<svg id="js_svg_root"></svg>`}</div>`;
	};

	return { template: htmlTemplate };
};
